/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { PosthogEvent } from './posthog.interfaces';

@Injectable()
export class PosthogService {
  currentPageName: string;

  capture(metrics: PosthogEvent) {
    if (!window['posthog']) return;
    window['posthog'].capture(metrics.eventName, {
      ...metrics.fields,
      pageName: this.currentPageName,
    });
  }

  /**
   * automatically $pageview events are captured only on page reload by posthog because
   * Angular is a SPA, so we use capturePageView to capture angular router page navigations
   */
  capturePageView(pageName: string, pageTitle: string) {
    this.currentPageName = pageName;
    this.capture({
      eventName: '$pageview',
      fields: { pageName, pageTitle },
    });
  }

  capturePageLeave(pageName: string, pageTitle: string) {
    this.capture({
      eventName: '$pageleave',
      fields: { pageName, pageTitle },
    });
  }
}
