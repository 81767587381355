/* Copyright 2024 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export type SupportedLanguage = 'en' | 'fr';
type Locales = {
  [lang in SupportedLanguage]: { [key: string]: string };
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _currentLanguage;
  private _locales: Locales = {
    en: {},
    fr: {},
  };

  supportedLanguages: SupportedLanguage[] = [];

  constructor() {
    this._currentLanguage = new BehaviorSubject<SupportedLanguage>(
      (localStorage.getItem('verto_lang') as SupportedLanguage) || 'en'
    );

    this._currentLanguage.subscribe((lang) => {
      localStorage.setItem('verto_lang', lang);
    });
  }

  $(key: string): string {
    const locale = this._locales[this.getLanguage()];
    return locale[key];
  }

  /**
   * Swaps out dynamic text in locale values.
   * Example: {APPOINTMENT_TYPE_PHONE_NUMBER} -> 123-123-1234
   * @param localeKey {string}
   * @param variables {object}
   * @returns parsed locale with dynamic data
   */
  $$(localeKey, variables = {}) {
    const localeContent = this.$(localeKey);
    return localeContent.replace(/{(.*?)}/g, (match, p1) => {
      return variables[p1] || match;
    });
  }

  setLocale(lang: SupportedLanguage, localeObj: { [key: string]: string }): void {
    this._locales[lang] = localeObj;
    this.supportedLanguages.push(lang);
    this.supportedLanguages = [...new Set(this.supportedLanguages)];
  }

  getLocale(lang: SupportedLanguage): { [key: string]: string } {
    return this._locales[lang];
  }

  getLanguage(): SupportedLanguage {
    return this._currentLanguage.value;
  }

  getLanguage$(): Observable<SupportedLanguage> {
    return this._currentLanguage.asObservable();
  }

  setLanguage(lang: SupportedLanguage): void {
    this._currentLanguage.next(lang);
  }
}
