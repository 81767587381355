/* Copyright 2024 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { ShellLoader } from '../../../../shell/src/app/ShellLoader';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface FormTemplate {
  form_json: any;
  setting: { surveyForm: any };
}

@Injectable()
export class FlowApiService {
  private backendUrl: string;

  constructor(private shellLoader: ShellLoader, private http: HttpClient) {
    this.backendUrl = this.shellLoader.content.backendUrl;
  }

  getFormTemplate(slug: string): Observable<{ form_templates: FormTemplate[] }> {
    const path = ['api', 'questionnaires', 'v1', 'form_templates'].join('/');
    return this.http.get<{ form_templates: FormTemplate[] }>(`${this.backendUrl}/${path}`, {
      params: {
        slug: slug,
        key: this.shellLoader.config.key,
      },
    });
  }
}
