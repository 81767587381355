/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigBasedStorage } from './config-based-storage';
import { ShellLoader } from '../ShellLoader';
import { OAuthService } from 'angular-oauth2-oidc';
import { SHELL_DATA } from 'engage-common';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SmartOnFhirService {
  refreshToken$ = new BehaviorSubject<string | null>(null);
  accessToken$ = new BehaviorSubject<string | null>(null);
  tokenLifeTime$ = new BehaviorSubject<number | null>(null);
  decodedAccessToken$ = new BehaviorSubject<object | null>(null);
  storage = inject(ConfigBasedStorage);
  _shellLoader = inject(ShellLoader);
  _oauthService = inject(OAuthService);
  shellData = inject(SHELL_DATA);
  httpClient = inject(HttpClient);

  private _periodicallyRefreshAccessToken = false;

  constructor() {
    this.accessToken$.subscribe((token) => {
      if (token) {
        this.storage.setItem('access_token', token);
      }
    });

    this.refreshToken$.subscribe((token) => {
      if (token) {
        this.storage.setItem('refresh_token', token);
      }
    });

    this.tokenLifeTime$.subscribe((expiresIn) => {
      if (expiresIn) {
        this.storage.setItem('expires_in', expiresIn.toString());
      }
    });
  }

  enablePeriodicAccessTokenRefresh(time?: number, callback?: (newToken: string) => void) {
    if (this._periodicallyRefreshAccessToken === true) {
      return;
    }
    this._periodicallyRefreshAccessToken = true;

    const { discoveryDocumentUrl } = this._shellLoader.config.application.smartOnFhirConfig;

    this._oauthService.loadDiscoveryDocument(discoveryDocumentUrl).then(() => {
      // Refresh the access token immediately
      this._refreshAccessToken();

      let refreshInterval = 1000 * 60 * 5; // 5 minute default
      if (time !== undefined) {
        refreshInterval = time;
      } else if (this.tokenLifeTime$.value) {
        const expiresInSeconds = this.tokenLifeTime$.value;
        refreshInterval = expiresInSeconds * 1000;
      } else if (this.storage.getItem('expires_in')) {
        const expiresInSeconds = parseInt(this.storage.getItem('expires_in'), 10);
        refreshInterval = expiresInSeconds * 1000;
      }

      refreshInterval = Math.floor(refreshInterval / 2); // Refresh halfway through the token's lifetime

      console.debug('Enabling periodic access token refresh');
      console.debug(`Refresh interval: ${refreshInterval}ms`);

      setInterval(() => {
        this._refreshAccessToken(callback);
      }, refreshInterval);
    });
  }

  private async _refreshAccessToken(callback?: (newToken: string) => void) {
    if (this._periodicallyRefreshAccessToken === false) {
      return;
    }

    console.debug('Refreshing access token');

    const { access_token, expires_in } = await this._oauthService.refreshToken();

    console.debug('Access token refreshed: ', access_token);

    callback?.(access_token);
    this.accessToken$.next(access_token);
    this.tokenLifeTime$.next(expires_in);
  }
}
