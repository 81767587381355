/* Copyright 2024 (Unpublished) Verto Inc. */

function timeBetween(params) {
  if (!params && params.length < 2) return false;

  const [start, end, timezone = 'America/Toronto'] = params;
  const currentTime = new Date().toLocaleString('en-US', {
    timeZone: timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const [startHour, startMinute] = start.split(':');
  const [endHour, endMinute] = end.split(':');
  const [currentHour, currentMinute] = currentTime.split(':');

  return (
    (currentHour > startHour || (currentHour == startHour && currentMinute >= startMinute)) &&
    (currentHour < endHour || (currentHour == endHour && currentMinute <= endMinute))
  );
}

export const CustomExpressions = {
  timeBetween,
};
