/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { Observable, firstValueFrom, of, map } from 'rxjs';
import { mergeMap, switchMap, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ShellLoader } from '../../../../shell/src/app/ShellLoader';
import { APP_KEY } from 'engage-common';
import { Appointment } from '../appointment/models/appointment.model';

@Injectable()
export class AppointmentService {
  private backendUrl: string;
  private _preferredTimezone: string = 'America/Toronto';
  bookingKey = '';
  timeSlots = [];

  constructor(
    private http: HttpClient,
    private shellLoader: ShellLoader,
    @Inject(APP_KEY) public appKey: string
  ) {
    this.shellLoader.configLoaded.subscribe(() => {
      this.backendUrl = this.shellLoader.content.backendUrl;
      this._preferredTimezone = this.shellLoader.content.open_clinic_timezone || 'America/Toronto';
    });
  }

  getCaptchaToken(recaptchaKey: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(`${this.backendUrl}/api/tokens/captcha_key`, {
      recaptcha_key: recaptchaKey,
      key: this.appKey,
    });
  }

  /**
   * Dates should be handled with the flow specified timezone to avoid
   * any possible booking issues
   * @param date
   * @param format Date Format, example: 'YYYY-MM-DD' or 'short'
   * @returns Formatted date with flow timezone information
   */
  dateTimeTransform(date: string | Date, format: string, locale?: string) {
    const datePipe = new DatePipe(locale || 'en');
    if (typeof date != 'string') {
      return datePipe.transform(date, format);
    }

    return datePipe.transform(date, format, this.getFlowTimeZoneOffset(date));
  }

  /**
   * Dates should always be represented in timezone determined by flow, not the browser
   * @param date
   * @returns Timezone offset used by Flow server
   */
  getFlowTimeZoneOffset(date: string): string {
    return date.substring(19);
  }

  /**
   * Service user should set their dates in the timezone determined by flow
   * @returns Timezone configured in MongoDB Engage config
   */
  public get preferredTimezone(): string {
    return this._preferredTimezone;
  }

  /**
   * @returns default date format
   */
  public get preferredDateFormat(): string {
    return 'yyyy-MM-ddT00:00:00.000ZZZZZ';
  }

  /**
   *
   * @param date
   * @returns localized datestring with preferred timezone
   */
  parseDate(date: string): Date {
    const newDateString = new Date(date).toLocaleString('en-US', {
      timeZone: this.preferredTimezone,
    });
    return new Date(newDateString);
  }

  getAvailabilityForDay(
    dayString: string,
    location: string,
    slotType: string
  ): Observable<{
    slots_per_day: { [key: string]: number };
    daily_slot_range: {
      [key: string]: {
        start: string;
        end: string;
      };
    };
  }> {
    const params = {
      day: dayString,
      location_id: location,
      slot_type: slotType,
      key: this.appKey,
      include_slot_range: 'true',
      include_all: true,
    };
    return this.http.get<{ slots_per_day: any; daily_slot_range: any }>(
      `${this.backendUrl}/api/cac-open-clinic/v1/slots/availability_range`,
      { params }
    );
  }

  async getAvailabilityForToday(
    preferredDateFormat: string,
    unitExternalIdentifier: string,
    appointmentTypeCode: string
  ): Promise<any> {
    const now = new Date();
    const nowStrFull = this.dateTimeTransform(new Date(), preferredDateFormat);
    const nowStrYMD = nowStrFull.slice(0, 10);

    let availability;
    try {
      availability = await firstValueFrom(
        this.getAvailabilityForDay(nowStrFull, unitExternalIdentifier, appointmentTypeCode)
      );
    } catch (error) {
      console.error('Error getting availability for today', error);
      return {};
    }

    const closeTime = availability.daily_slot_range[nowStrYMD]?.end;
    const openTime = availability.daily_slot_range[nowStrYMD]?.start;

    const closeDate = new Date(closeTime);
    const openDate = new Date(openTime);

    if (isNaN(Number(closeDate)) || isNaN(Number(openDate))) {
      console.error('Invalid Slot Date Range');
      return {};
    }

    const hasSlots =
      availability.daily_slot_range[nowStrYMD] && openDate <= now && closeDate >= now;

    return {
      hasSlots,
      closeTime: this.parseDate(closeTime),
      openTime: this.parseDate(openTime),
    };
  }

  async hasAvailabilityForToday(
    preferredDateFormat: string,
    unitExternalIdentifier: string,
    appointmentTypeCode: string
  ): Promise<boolean> {
    const availability = await this.getAvailabilityForToday(
      preferredDateFormat,
      unitExternalIdentifier,
      appointmentTypeCode
    );

    if (!availability) return false;

    return availability.hasSlots;
  }

  getAvailabilityForDateRange(
    startDayString: string,
    endDayString: string,
    location: string,
    slotType: string
  ): Observable<{
    slots_per_day: { [key: string]: number };
    daily_slot_range: {
      [key: string]: {
        start: string;
        end: string;
      };
    };
  }> {
    const params = {
      day: startDayString,
      end_day: endDayString,
      location_id: location,
      slot_type: slotType,
      key: this.appKey,
      include_slot_range: 'true',
      include_all: true,
    };
    return this.http.get<{ slots_per_day: any; daily_slot_range: any }>(
      `${this.backendUrl}/api/cac-open-clinic/v1/slots/availability_range`,
      { params }
    );
  }

  getTimeSlotsForDay(
    dayString: string,
    location: string,
    slotType: string,
    captchaResponse: string,
    secretCode: string,
    appointmentTypeToken: string,
    token: string
  ) {
    const apiKey = (window as any).backendApiKey; // temporary solution

    const params = {
      day: dayString,
      location_id: location,
      slot_type: slotType,
      recaptcha_key: captchaResponse,
      key: apiKey,
      secret_code: secretCode,
    };

    if (appointmentTypeToken) {
      params['appointment_type_token'] = appointmentTypeToken;
    }

    if (!apiKey) {
      return;
    }

    this.timeSlots = [];

    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return this.http
      .get(`${this.backendUrl}/api/cac-open-clinic/v1/slots`, {
        params,
        headers,
      })
      .pipe(
        switchMap((data: any) => {
          data.slots = data.slots.filter((appointment) => {
            const today = Date.now();
            return +new Date(appointment.start) > today;
          });
          this.timeSlots = data.slots;
          this.bookingKey = data.booking_key;
          return of(data);
        })
      );
  }

  holdAppointment(
    appointment: Appointment,
    captchaToken?: string,
    accessToken?: string
  ): Observable<any> {
    const key = (window as any).backendApiKey; // temporary solution

    const { time_slot, slot_type, location_id } = appointment;

    const start = time_slot.startDateString;
    const end = time_slot.endDateString;

    const headers = {};
    if (captchaToken) {
      headers['Authorization'] = `Bearer ${captchaToken}`;
    } else if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return this.http.post(
      `${this.backendUrl}/api/cac-open-clinic/v1/appointments`,
      {
        start,
        end,
        location_id,
        slot_type,
        key,
        booking_key: this.bookingKey,
      },
      {
        headers,
      }
    );
  }

  bookAppointment(appointment: Appointment): Observable<any> {
    const apiKey = (window as any).backendApiKey; // temporary solution

    const params: any = appointment;
    params.key = apiKey;

    const datePipe = new DatePipe('en');
    params.patient_dob = datePipe.transform(params.patient_dob, 'yyyy-MM-dd');

    return this.http.patch(
      `${this.backendUrl}/api/cac-open-clinic/v1/appointments/${params.id}`,
      params
    );
  }
}
